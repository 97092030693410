@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    color: #282828
}

a{
    color: #1C2D86;
    text-decoration:underline;
}