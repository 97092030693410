button.bg-MMI {
    background-image:url(../assets/MMI.webp);
}

button.bg-TC {
    background-image:url(../assets/TC.webp);
}

button.bg-GCCD {
    background-image:url(../assets/GCCD.webp);
}

button.bg-Chimie {
    background-image:url(../assets/Chimie.webp);
}

button.bg-CJ {
    background-image:url(../assets/CJ.webp);
}

button.bg-CS {
    background-image:url(../assets/CS.webp);
}

button.bg-GACO {
    background-image:url(../assets/GACO.webp);
}

button.bg-GB {
    background-image:url(../assets/GB.webp);
}

button.bg-GCGP {
    background-image:url(../assets/GCGP.webp);
}

button.bg-GEA {
    background-image:url(../assets/GEA.webp);
}

button.bg-GEII {
    background-image:url(../assets/GEII.webp);
}

button.bg-GIM {
    background-image:url(../assets/GIM.webp);
}

button.bg-GMP {
    background-image:url(../assets/GMP.webp);
}

button.bg-HSE {
    background-image:url(../assets/HSE.webp);
}

button.bg-Info-Com {
    background-image:url(../assets/Info-Com.webp);
}

button.bg-INFO {
    background-image:url(../assets/INFO.webp);
}

button.bg-MLT {
    background-image:url(../assets/MLT.webp);
}

button.bg-MP {
    background-image:url(../assets/MP.webp);
}

button.bg-MT2E {
    background-image:url(../assets/MT2E.webp);
}

button.bg-PEC {
    background-image:url(../assets/PEC.webp);
}

button.bg-QLIO {
    background-image:url(../assets/QLIO.webp);
}

button.bg-RT {
    background-image:url(../assets/RT.webp);
}

button.bg-SD {
    background-image:url(../assets/SD.webp);
}

button.bg-SGM {
    background-image:url(../assets/SGM.webp);
}

button.bg-DEFAULT {
    background-image:none;
}